import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Exceed clients’ and colleagues’ expectations",
    para: "Exceeding clients' and colleagues' expectations means consistently surpassing what they anticipate or require, whether in quality, service, or innovation. It involves understanding their needs and goals deeply and consistently delivering results that go above and beyond, fostering trust and long-term relationships"
  },
  {
    id: 2,
    title:
      "Take ownership and question the status quo in a constructive manner",
    para: "means proactively assuming responsibility for tasks and projects while also challenging existing norms and processes in a positive manner. It involves actively seeking improvements, suggesting innovative ideas, and driving positive change while being accountable for outcomes"
  },
  {
    id: 3,
    title:
      "Be brave, curious and experiment – learn from all successes and failures",
    para: "Being brave, curious, and experimental means embracing risk and uncertainty to explore new ideas and approaches. It's about learning from both successes and failures, continuously evolving and improving. This mindset fosters innovation, growth, and resilience, enabling us to adapt and thrive in a rapidly changing world."
  },
  {
    id: 4,
    title: "Act in a way that makes all of us proud",
    para: "conducting ourselves with integrity, empathy, and excellence in everything we do. It's about upholding our values, treating others with respect, and striving for excellence in our work. By embodying these principles, we contribute to a positive and supportive environment where everyone can thrive and succeed"
  },
  {
    id: 5,
    title: "Build an inclusive, transparent and socially responsible culture",
    para: "It involves actively promoting inclusion, providing equal opportunities for all, and ensuring transparency in decision-making processes. By prioritizing social responsibility, we contribute to a positive impact on our communities and the world at large, creating a culture where everyone feels valued and empowered to contribute."
  }
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Our Valus"
          title="Why should you work with us?"
          description="Work with us because we're driven by a passion for creating impactful software that addresses global challenges. Our team fosters innovation, collaboration, and a commitment to positive change. By joining forces with us, you'll contribute to meaningful projects that make a real difference in the world."
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
