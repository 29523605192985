import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h2 className="title">Building software for world changers</h2>
                <p>
                  Building software for world changers" involves creating
                  technological solutions designed to address global issues and
                  promote positive change. This entails developing innovative
                  software applications and platforms aimed at tackling
                  challenges such as healthcare access, education equity,{" "}
                </p>
                <p>
                  environmental sustainability, and social justice. The
                  goal is to leverage technology to make a meaningful impact on
                  society by creating accessible, inclusive, and sustainable
                  solutions.
                </p>
                <Link to="#" className="axil-btn btn-fill-primary btn-large">
                  Read More
                </Link>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="10" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years on the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp end="1500" duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered so far
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
