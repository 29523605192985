import React from "react";
import { Link } from "react-router-dom";

const AboutFive = () => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/about-2.png"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-team">
              <div className="section-heading heading-left">
                <span className="subtitle">Our Team</span>
                <h2>Strength in Unity.</h2>
                <p>
                  Encapsulates the idea that by coming
                  together as a cohesive group, we can achieve far more than we
                  could alone. It emphasizes the power of collaboration,
                  teamwork, and collective effort in achieving common goals and
                  overcoming challenges. This principle underscores the
                  importance of unity in fostering resilience, innovation, and
                  success in our endeavors.
                </p>
                <Link to="#" className="axil-btn btn-large btn-fill-primary">
                  Our Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
