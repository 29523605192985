import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/service-one"}>Service</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/project-width-one"}>Portfolio</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/project-grid-three"}>Projects</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/case-study"}>Case Study</Link></li>
                <li className="menu-item-has-children">
                    <Link to="#">More <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/team"}>Team</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;